import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import { Redirect } from "react-router-dom";
import { CloudUpload, Equalizer, History, Subject, PlayArrowRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  categorieTitle: {
    fontWeight: 400,
    fontSize: 18,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
}));

export default function TemporaryDrawer({ open, switchMenu }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    console.log(open);
    setState({ ...state, left: open });
  }, [open]);

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const SideList = () => {
    return (
      <div className={classes.list} role="presentation">
        <h3 className={classes.categorieTitle}>Menu</h3>
        <List>
          <ListItem
            button
            onClick={() => {
              setRedirect("/home");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Accueil" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setRedirect("/roomsMonitoring");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <Subject />
            </ListItemIcon>
            <ListItemText primary="Suivi des salles " />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setRedirect("/lastAppointments");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <History />
            </ListItemIcon>
            <ListItemText primary="Derniers rendez-vous" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setRedirect("/statistics");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <Equalizer />
            </ListItemIcon>
            <ListItemText primary="Statistiques" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setRedirect("/uploadVideo");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <CloudUpload />
            </ListItemIcon>
            <ListItemText primary="Upload une vidéo" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setRedirect("/managementVideo");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <CloudUpload/>
            </ListItemIcon>
            <ListItemText primary="Gestion des vidéo" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setRedirect("/allvideo");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <PlayArrowRounded />
            </ListItemIcon>
            <ListItemText primary="Videos" />
          </ListItem>
        </List>
        <Divider />
      </div>
    );
  };
  return (
    <>
      {redirect && <Redirect push to={redirect} />}
      <Drawer
        open={state.left}
        onClose={() => {
          toggleDrawer("left", false);
          switchMenu(false);
        }}
      >
        <SideList />
      </Drawer>
    </>
  );
}
