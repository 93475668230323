import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import {
  Avatar,
  CssBaseline,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useFirebase } from "../../Firebase/FirebaseContext";
import FinalForm from "../Elements/FinalForm";
import InputText from "../Inputs/InputText";
import CustomButton from "../Buttons/CustomButton";
import {
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/login.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing("auto", 8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const { firebase } = useFirebase();
  const history = useHistory();
  const classes = useStyles();

  const onSubmit = async (values) => {
    const email = values.email.toLowerCase().trim();
    setPersistence(firebase.auth, browserLocalPersistence).then(() => {
      signInWithEmailAndPassword(firebase.auth, email, values.password)
        .then((userCredential) => {
          firebase.currentUser = userCredential.user;
          const docRef = doc(firebase.db, "users", userCredential.user.email);
          getDoc(docRef).then((docData) => {
            getDoc(docData.data().belongs_to).then((labodata) => {
              localStorage.setItem(
                "laboratory",
                docData.data().belongs_to._key.path.segments[6]
              );
              localStorage.setItem("structure", labodata.data().structure);
              localStorage.setItem("token", userCredential.user.accessToken);
              window.location.reload();
            });
          });
        })
        .catch((e) => {
          console.log("Login unsuccessful");
        });
    });
  };

  return (
    <Grid container component="main" className={classes.root}>
      {localStorage.laboratory && <Redirect push to="/home" />}
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        style={{ display: "flex" }}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connexion
          </Typography>
          <FinalForm onSubmit={onSubmit} style={classes.form}>
            <InputText name="email" label="Adresse mail" required />
            <InputText
              name="password"
              label={"Mot de passe"}
              type={"password"}
              required
            />
            <CustomButton
              type="submit"
              className={classes.submit}
              title="Se connecter"
              fullWidth={true}
            />
          </FinalForm>
          <Box mt={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="https://gyra-lean.com/">
                GYRA
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
