import React from "react";
import { TextField } from "@material-ui/core";
import { Field } from "react-final-form";

export default function InputText({
  name,
  label,
  type = "text",
  variant = "outlined",
  required = false,
  classes = {},
  fullWidth = true,
  value,
  ownOnChange = () => {},
}) {
  return (
    <Field
      name={name}
      component="input"
      type={type}
      placeholder="First Name"
      label={label}
    >
      {(props) => (
        <TextField
          name={name}
          label={label}
          type={type}
          classes={classes}
          value={props.input.value}
          onChange={props.input.onChange}
          fullWidth={fullWidth}
          required={required}
          variant={variant}
          margin="normal"
        />
      )}
    </Field>
  );
}
