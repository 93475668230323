import {
  Button,
  Divider,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { AccountCircle, CloudUpload } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useFirebase } from "../../Firebase/FirebaseContext";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import CustomButton from "../Buttons/CustomButton";
import { useLocation } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  getAuth,
  updateProfile,
  updatePassword as updatePasswordFn,
} from "firebase/auth";
import { useSnackbar } from "notistack";
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: "inherit",
    color: theme.palette.primary.main,
  },
  card: {
    margin: "50px auto",
    width: 500,
    padding: 50,
    borderTopLeftRadius: theme.spacing(5),
    borderBottomRightRadius: theme.spacing(5),
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.8)",
  },
}));

export default function Profile() {
  const { firebase } = useFirebase();
  const classes = useStyles();
  const location = useLocation();
  const userEmail = location.state.user;
  const { enqueueSnackbar } = useSnackbar();
  const [currentUser, setCurrentUser] = useState();
  const [userImage, setUserImage] = useState();
  const [file, setFile] = useState();
  const [refresh, setRefresh] = useState(false);
  const selectFile = useRef(null);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (userEmail) {
      getDoc(doc(firebase.db, "users", userEmail)).then((docData) => {
        setCurrentUser(docData.data());
        const imgRef = ref(getStorage(), docData.data().profile_picture);
        getDownloadURL(ref(getStorage(), imgRef))
          .then((imgUrl) => setUserImage(imgUrl))
          .catch((e) => console.log(e));
      });
    }
  }, [refresh]);

  const fileSelectionClick = () => {
    selectFile.current.click();
  };

  const updateFirebasePassword = () => {
    const auth = getAuth();
    console.log(auth.currentUser);
    updatePasswordFn(auth.currentUser, password)
      .then(() => {
        enqueueSnackbar("Mot de passe modifié avec succès", {
          variant: "success",
        });
        setUpdatePassword(false);
      })
      .catch((e) => {
        enqueueSnackbar("Erreur", {
          variant: "error",
        });
      });
  };

  const submit = () => {
    const storage = getStorage();
    const trimedName = file.name.replace(/\s+/g, "");
    const url = `users/${userEmail}/${trimedName}`;
    const profileImgRef = ref(storage, url);
    uploadBytes(profileImgRef, file).then(() => {
      const auth = getAuth();
      updateProfile(auth.currentUser, {
        photoURL: url,
      })
        .then(() => {
          const userRef = doc(firebase.db, "users", userEmail);
          getDoc(userRef).then((docData) => {
            if (userImage) {
              const pictureRef = ref(storage, docData.data().profile_picture);
              deleteObject(pictureRef);
            }
            updateDoc(userRef, {
              profile_picture: url,
            });
            enqueueSnackbar("Votre image de profil à bien été changée", {
              variant: "success",
            });
            setRefresh(!refresh);
          });
        })
        .catch((e) => {
          enqueueSnackbar("Erreur", {
            variant: "error",
          });
        });
    });
  };

  return (
    <Paper className={classes.card}>
      <Typography
        variant="h3"
        style={{ display: "flex", alignItems: "center" }}
      >
        <AccountCircle
          className={classes.avatar}
          onClick={() => console.log("image click")}
        />
        {currentUser
          ? `${currentUser.name} ${currentUser.lastName}`
          : "Profil utilisateur"}
      </Typography>
      <Divider style={{ margin: "20px 0" }} />

      {currentUser && (
        <Typography variant="h5" style={{ marginTop: 20, marginBottom: 20 }}>
          {userEmail}
        </Typography>
      )}
      {userImage && (
        <img
          src={userImage}
          style={{
            width: 400,
            height: 400,
            display: "block",
            marginBottom: 20,
          }}
          alt=""
        />
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={fileSelectionClick}
        style={{ margin: 5 }}
      >
        {file ? (
          file.name
        ) : (
          <>
            <CloudUpload style={{ marginRight: 5 }} />
            Ajouter un fichier
          </>
        )}
        <input
          type="file"
          hidden
          accept=".png,.jpg"
          ref={selectFile}
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
      </Button>
      <CustomButton title="Confirmer" onClick={submit} />
      {!updatePassword && (
        <CustomButton
          title="Modifier le mot de passe du compte"
          onClick={() => {
            setUpdatePassword(!updatePassword);
          }}
        />
      )}

      {updatePassword && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: 20,
              borderTop: "1px solid black",
            }}
          >
            <Typography
              variant="h5"
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              Modifier le mot de passe
            </Typography>
            <Typography
              variant="body1"
              style={{ marginBottom: 20, textAlign: "center" }}
            >
              Veuillez entrer votre nouveau mot de passe respectant les critères
              de sécurité suivants:
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <li>8 caractères minimum</li>
                <li>1 majuscule</li>
                <li>1 chiffre</li>
                <li>1 caractère spécial</li>
              </ul>
            </Typography>
            <input
              type="password"
              placeholder="Nouveau mot de passe"
              onChange={(e) => setPassword(e.target.value)}
              style={{
                width: "100%",
                padding: 10,
                marginBottom: 20,
              }}
            />
            <CustomButton title="Confirmer" onClick={updateFirebasePassword} />
          </div>
        </>
      )}
    </Paper>
  );
}
