import React, { useState } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  addDoc,
} from "firebase/firestore";

const AddCommentsToLaboratories = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const db = getFirestore();

  // Liste des commentaires à ajouter
  const commentsToAdd = [
    "2 ordos",
    "Ordo renouvelable",
    "Mutuelle OK",
    "Mutuelle par mail",
    "CMU",
    "Urines OK",
  ];

  const addComments = async () => {
    setLoading(true);
    setMessage("");

    try {
      const laboratoriesRef = collection(db, "laboratories");
      const querySnapshot = await getDocs(laboratoriesRef);

      // Parcourt chaque laboratoire
      for (const labDoc of querySnapshot.docs) {
        const labId = labDoc.id;

        // Référence vers la sous-collection `comments` pour ce labo
        const commentsRef = collection(db, `laboratories/${labId}/comments`);

        // Ajoute chaque commentaire
        for (const comment of commentsToAdd) {
          await addDoc(commentsRef, { text: comment });
        }
      }

      setMessage("Commentaires ajoutés avec succès !");
    } catch (error) {
      console.error("Erreur lors de l'ajout des commentaires :", error);
      setMessage("Une erreur est survenue lors de l'ajout des commentaires.");
    }

    setLoading(false);
  };

  return (
    <div>
      <button onClick={addComments} disabled={loading}>
        {loading ? "Ajout en cours..." : "Ajouter des commentaires"}
      </button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AddCommentsToLaboratories;
