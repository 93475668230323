import React, { useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Button,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";
import moment from "moment";
import ExpressAPI from "../../Utils/ExpressAPI";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

export default function AppointmentCard({ appointment }) {
  // const [timer, setTimer] = useState();

  // useEffect(() => {
  //   let interval;
  //   console.log(appointment.started_at);
  //   if (appointment.started_at) {
  //     interval = setInterval(() => {
  //       const a = moment();
  //       let b = moment(moment.utc(appointment.started_at?.seconds * 1000));
  //       let diff_s = a.diff(b, "seconds");
  //       let formatted = moment.utc(diff_s * 1000).format("HH:mm:ss");
  //       setTimer(formatted);
  //       console.log(formatted);
  //     }, 1000);
  //   }
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const classes = useStyles();
  const [color, setColor] = useState(appointment.seen && "rgb(154 255 154)");
  const [loading, setLoading] = useState(false);

  const downloadPDF = async () => {
    try {
      setLoading(true);
      const name = appointment.name ? `_${appointment.name}` : "";
      const lastName = appointment.lastName ? `_${appointment.lastName}` : "";
      const time = moment
        .unix(appointment.ended_at.seconds)
        .format("DD_MM_YYYY");
      const fileName = `${time}${name}${lastName}.pdf`;
      const res = await ExpressAPI(
        "GET",
        `pdf/getAppointment/${appointment.id}`,
        [],
        "blob"
      );

      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoading(false);
      localStorage.setItem(appointment.id, 1);
      setColor("#00e676");
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const downloadDocumentFromFirebaseStorage = async (path, infos) => {
    try {
      setLoading(true);

      const storage = getStorage();
      const pathReference = ref(storage, path);

      getDownloadURL(pathReference)
        .then((url) => {
          const xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            const blob = xhr.response;
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = fileURL;
            // Modification ici pour changer le nom du fichier
            const newName = getFormattedName(infos);
            link.setAttribute("download", newName);

            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setLoading(false);
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  // Fonction pour formater le nom du fichier
  const getFormattedName = (infos) => {
    const firstNameInitials = infos.name.substring(0, 3).toUpperCase();
    const lastNameInitials = infos.lastName.substring(0, 3).toUpperCase();
    return `${infos.day}-${infos.type}-${firstNameInitials}-${lastNameInitials}.jpg`;
  };

  const formatedAppointment = {
    lastName: {
      key: "Nom",
      data: appointment.lastName,
    },
    name: {
      key: "Prénom",
      data: appointment.name,
    },
    birthday: {
      key: "Date de naissance",
      data: appointment.birthday,
    },
    appointment: {
      key: "Rendez-vous",
      data: appointment.appointment ? "Oui" : "Non",
    },
    created_at: {
      key: "Date de création",
      data: appointment.created_at?.seconds
        ? moment.unix(appointment.created_at.seconds).format("DD/MM/yyyy")
        : new Date(appointment.created_at).toLocaleDateString(),
    },
    technician_came_at: {
      key: "Heure d'arrivée du technicien",
      data:
        appointment.technician_came_at &&
        moment
          .unix(appointment.technician_came_at.seconds)
          .format("DD/MM/YYYY HH:mm:ss"),
    },
    ended_at: {
      key: "Heure de fin de rendez-vous",
      data:
        appointment.ended_at &&
        moment.unix(appointment.ended_at.seconds).format("DD/MM/YYYY HH:mm:ss"),
    },
    prescription: {
      key: "Ordonnance",
      data: appointment.prescription ? "Oui" : "Non",
    },
    prescriptiontwo: {
      key: "Ordonnance 2",
      data: appointment.prescriptiontwo ? "Oui" : "Non",
    },
    mutual: {
      key: "Mutuelle",
      data: appointment.mutual ? "Oui" : "Non",
    },
    document: {
      key: "Document",
      data: appointment.document ? "Oui" : "Non",
    },
  };
  return (
    <Card
      className={classes.card}
      style={{ border: color ? `7px solid ${color}` : "" }}
      // onClick={() => downloadPDF(appointment.id)} // Ancienne fonctionnalité cliquable sur la carte
    >
      <CardContent>
        {Object.keys(formatedAppointment).map(
          (item, i) =>
            formatedAppointment[item].data && (
              <Typography key={`appointment-item-${i}`}>
                {formatedAppointment[item].key} :{" "}
                {formatedAppointment[item].data}
              </Typography>
            )
        )}
        {loading && <LinearProgress />}
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: 10 }}
          onClick={() => downloadPDF(appointment.id)}
        >
          Télécharger le résumé en PDF
        </Button>
        {appointment.document && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: 10 }}
            onClick={() =>
              downloadDocumentFromFirebaseStorage(appointment.document, {
                name: appointment.name,
                lastName: appointment.lastName,
                type: "document",
                day: moment
                  .unix(appointment.technician_came_at.seconds)
                  .format("YYYY-MM-DD"),
              })
            }
          >
            Télécharger le document
          </Button>
        )}
        {appointment.IDCard && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: 10 }}
            onClick={() =>
              downloadDocumentFromFirebaseStorage(appointment.IDCard, {
                name: appointment.name,
                lastName: appointment.lastName,
                type: "piece-identite",
                day: moment
                  .unix(appointment.technician_came_at.seconds)
                  .format("YYYY-MM-DD"),
              })
            }
          >
            Télécharger la pièce d'identité
          </Button>
        )}
        {appointment.prescription && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: 10 }}
            onClick={() =>
              downloadDocumentFromFirebaseStorage(appointment.prescription, {
                name: appointment.name,
                lastName: appointment.lastName,
                type: "ordo1",
                day: moment
                  .unix(appointment.technician_came_at.seconds)
                  .format("YYYY-MM-DD"),
              })
            }
          >
            Télécharger l'ordonnance
          </Button>
        )}
        {appointment.prescriptiontwo && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: 10 }}
            onClick={() =>
              downloadDocumentFromFirebaseStorage(appointment.prescriptiontwo, {
                name: appointment.name,
                lastName: appointment.lastName,
                type: "ordo2",
                day: moment
                  .unix(appointment.technician_came_at.seconds)
                  .format("YYYY-MM-DD"),
              })
            }
          >
            Télécharger l'ordonnance 2
          </Button>
        )}
        {appointment.mutual && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: 10 }}
            onClick={() =>
              downloadDocumentFromFirebaseStorage(appointment.mutual, {
                name: appointment.name,
                lastName: appointment.lastName,
                type: "mutuelle",
                day: moment
                  .unix(appointment.technician_came_at.seconds)
                  .format("YYYY-MM-DD"),
              })
            }
          >
            Télécharger la mutuelle
          </Button>
        )}
        {/* 
          <button
          onClick={() => {
            deleteDoc(doc(firebase.db, "appointements", appointment.id));
          }}
        >
          Supprimer le rdv
        </button>
          */}
      </CardContent>
    </Card>
  );
}
const useStyles = makeStyles(() => ({
  card: {
    margin: 25,
    padding: 10,
    backgroundColor: "rgba(255,255,255,0.5)",
    boxShadow: "0 5px 10px rgba(100, 100, 100, 0.2)",
    width: 450,
    transition: "450ms",
  },
}));
