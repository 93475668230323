import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  CssBaseline,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useFirebase } from "../../Firebase/FirebaseContext";
import FinalForm from "../Elements/FinalForm";
import InputText from "../Inputs/InputText";
import CustomButton from "../Buttons/CustomButton";
import {
  browserLocalPersistence,
  setPersistence,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { collection, doc, setDoc, getDocs } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/images/login.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing("auto", 8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  select: {
    minWidth: "200px",
  },
}));

export default function Signup() {
  const { firebase } = useFirebase();
  const history = useHistory();
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("");
  const [belongsTo, setBelongsTo] = useState("");
  const [currentUser, setCurrentUser] = useState(null);

  const onSubmit = async (values) => {
    console.log(values);
    setPersistence(firebase.auth, browserLocalPersistence).then(() => {
      createUserWithEmailAndPassword(
        firebase.auth,
        values.email,
        values.password
      )
        .then((userCredential) => {
          //const uid = userCredential.user.uid;
          const userRef = doc(firebase.db, "users", values.email);
          setDoc(userRef, {
            firstName: values.firstName,
            lastName: values.lastName,
            name: values.firstName,
            email: values.email,
            status,
            belongs_to: doc(firebase.db, belongsTo),
          }).then(() => {
            history.push("/home");
          });
        })
        .catch((e) => {
          console.log("Signup unsuccessful");
        });
    });
  };

  // Vérifier si l'utilisateur est déjà connecté
  useEffect(() => {
    setCurrentUser(firebase.auth.currentUser);
  }, [firebase.auth.currentUser]);

  const laboratoriesRef = collection(firebase.db, "laboratories");
  const [laboratories, setLaboratories] = useState([]);
  useEffect(() => {
    getDocs(laboratoriesRef).then((querySnapshot) => {
      const labs = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        path: doc.ref.path,
      }));
      setLaboratories(labs);
    });
  }, []);

  console.log(laboratories);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        style={{ display: "flex" }}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Inscription
          </Typography>
          <FinalForm onSubmit={onSubmit} style={classes.form}>
            <InputText
              name="firstName"
              label="Prénom"
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <InputText
              name="lastName"
              label="Nom"
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <InputText
              name="email"
              label="Adresse mail"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputText
              name="password"
              label="Mot de passe"
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControl className={classes.select}>
              <InputLabel id="status-label">Statut</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
              >
                <MenuItem value="hostess">Hotesse</MenuItem>
                <MenuItem value="technician">Préleveur</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.select}>
              <InputLabel id="belongs-to-label">Laboratoire</InputLabel>
              <Select
                labelId="belongs-to-label"
                id="belongs-to"
                value={belongsTo}
                onChange={(e) => setBelongsTo(e.target.value)}
                required
              >
                <MenuItem value="">Sélectionner un laboratoire</MenuItem>
                {/* Récupérer les données de la collection laboratories et les afficher sous forme de liste */}
                {laboratories.map((lab) => (
                  <MenuItem key={lab.path} value={lab.path}>
                    {lab.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CustomButton
              type="submit"
              className={classes.submit}
              title="S'inscrire"
              fullWidth={true}
            />
          </FinalForm>
          <Box mt={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="https://gyra-lean.com/">
                GYRA
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
