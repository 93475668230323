import { Divider, Grid, Typography } from "@material-ui/core";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFirebase } from "../../../Firebase/FirebaseContext";
import AppointmentCard from "../../Elements/AppointmentCard";

export default function RoomHistory() {
  const location = useLocation();
  const { firebase } = useFirebase();
  const [roomHistory, setRoomHistory] = useState([]);
  const [currentAppointment, setCurrentAppointment] = useState();
  const room = location.state.data;

  useEffect(() => {
    const roomRef = doc(firebase.db, `rooms/${room.id}`);
    getDoc(roomRef)
      .then((docData) => {
        if (docData.data().appointementId) {
          const currentAppointmentRef = doc(
            firebase.db,
            `appointements/${docData.data().appointementId}`
          );
          getDoc(currentAppointmentRef)
            .then((appDoc) => {
              setCurrentAppointment(appDoc.data());
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));

    const appointmentsRef = collection(firebase.db, `appointements`);

    const q = query(
      appointmentsRef,
      where("roomId", "==", room.id),
      orderBy("started_at", "desc"),
      limit(30)
    );
    getDocs(q)
      .then((qSnapshot) => {
        let temp = [];
        qSnapshot.forEach((appointment) => {
          temp.push({ id: appointment.id, ...appointment.data() });
        });
        console.log(temp);
        setRoomHistory(temp);
      })
      .catch((e) => console.log(e));
  }, []);
  console.log(room);

  return (
    <>
      <Typography
        variant="h3"
        color="primary"
        align="center"
        style={{ marginTop: 50 }}
      >
        Historique de la salle {room?.room?.name}
      </Typography>
      {currentAppointment && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 25,
            paddingBottom: 25,
          }}
        >
          <Typography variant="h5">Rendez-vous actuellement : </Typography>
          <AppointmentCard appointment={currentAppointment} />
          <Divider style={{ width: "100%" }} />
        </div>
      )}
      <Grid container style={{ justifyContent: "space-around", padding: 20 }}>
        {roomHistory.length > 0 &&
          roomHistory.map((appointment, i) => {
            return (
              <AppointmentCard
                appointment={appointment}
                key={`appointment-uid-${i}`}
              />
            );
          })}
      </Grid>
    </>
  );
}
