import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  collection,
  updateDoc,
  doc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
} from "firebase/storage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../../../Firebase/FirebaseContext";
import CustomButton from "../../Buttons/CustomButton";
import Title from "../../Elements/Title";

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "2rem",
    margin: "2rem",
  },
  videoItem: {
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.4)",
    padding: 20,
  },
}));

const DeleteModal = ({ onValidate, children }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Suppression</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous vraiment supprimer cette vidéo des salles?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Non
          </Button>
          <Button
            onClick={() => {
              handleClose();
              onValidate();
            }}
            color="secondary"
          >
            Oui
          </Button>
        </DialogActions>
      </Dialog>
      <div onClick={handleClickOpen}>{children}</div>
    </>
  );
};

export default function ManagementVideo() {
  const classes = useStyles();
  const history = useHistory();
  const { firebase } = useFirebase();
  const [videos, setVideos] = useState();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        console.log();
        const videosRef = collection(
          firebase.db,
          `laboratories/` + localStorage.currentLab + `/videos`
        );
        const videosDocs = await getDocs(videosRef);
        let temp = [];
        videosDocs.forEach((video) =>
          temp.push({
            id: video.id,
            url: video.data().path,
            name: {
              key: "Nom",
              data: video.data().title,
            },
            minAge: {
              key: "Age minimum",
              data: video.data().minAge,
            },
            maxAge: {
              key: "Age maximum",
              data: video.data().maxAge,
            },
            sex: {
              key: "Sexe",
              data: video.data().sex === "h" ? "Homme" : "Femme",
            },
            createdAt: {
              key: "Date d'ajout",
              data: moment
                .unix(video.data().createdAt.seconds)
                .format("DD/MM/YYYY HH:mm:ss"),
            },
            laboratoryId: video.data().laboratoryId,
          })
        );
        let orderedArr = temp.sort((a, b) =>
          a.name.data.localeCompare(b.name.data)
        );
        setVideos(orderedArr);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [refresh]);

  const redirectToVideo = async (url) => {
    const storage = getStorage();
    const path = await getDownloadURL(ref(storage, url));
    window.open(path, "_blank");
  };
  const redirectToEdit = (id) => {
    history.push("/editVideo", {
      id: id,
    });
  };

  const deleteVideo = async (id) => {
    try {
      const docRef = doc(
        firebase.db,
        `laboratories/` + localStorage.currentLab + `/videos/${id}`
      );
      await deleteDoc(docRef);
      setRefresh(!refresh);
    } catch (e) {
      // const docRef = doc(
      //   firebase.db,
      //   `laboratories/` + localStorage.laboratory + `videos/${id}`
      // );
      // await deleteDoc(docRef);
      console.log(e);
    }
  };
  return (
    <>
      <Title>Gestion des vidéos</Title>
      <div className={classes.gridContainer}>
        {videos &&
          videos.map((video) => {
            console.log(video);
            return (
              <div className={classes.videoItem}>
                {Object.keys(video).map(
                  (item, i) =>
                    video[item] &&
                    video?.[item]?.data && (
                      <Typography key={`appointment-item-${i}`}>
                        {video[item].key} : {video[item].data}
                      </Typography>
                    )
                )}
                <div style={{ display: "flex" }}>
                  <CustomButton
                    title="Regarder la vidéo"
                    fullWidth
                    onClick={() => redirectToVideo(video.url)}
                  />
                  <div style={{ width: 10 }}></div>
                  <CustomButton
                    title="Modifier la vidéo"
                    fullWidth
                    onClick={() => redirectToEdit(video.id)}
                    color="#02EF00"
                  />
                </div>

                <DeleteModal onValidate={() => deleteVideo(video.id)}>
                  <CustomButton
                    style={{ marginTop: 10 }}
                    title="Supprimer la vidéo des salles"
                    color="secondary"
                    fullWidth
                  />
                </DeleteModal>
              </div>
            );
          })}
      </div>
    </>
  );
}
