import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://29a47d3320d8717227c0b2b5d82986be@o332326.ingest.us.sentry.io/4508171928403968",
  integrations: [],
  debug: true,
});

ReactDOM.render(<App />, document.getElementById("root"));
