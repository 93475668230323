import React, { useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { CssBaseline, Grid, Typography, makeStyles } from "@material-ui/core";
import { useFirebase } from "../../Firebase/FirebaseContext";
import {
  doc,
  onSnapshot,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { CreditCard, Description } from "@material-ui/icons";
import moment from "moment";
import Animation from "../Elements/Animation";
import ReactPlayer from "react-player";

export default function WaitingScreen() {
  const { firebase } = useFirebase();
  const classes = useStyles();
  const location = useLocation();
  const locationData = location.state.data;
  const [technician, setTechnician] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [technicianImg, setTechnicianImg] = useState("");
  const [videoUrls, setVideoUrls] = useState([]);
  const [messageLongWaiting, setMessageLongWaiting] = useState("");
  const [waitingTime, setWaitingTime] = useState(0);
  const [enableWaitingMessage, setEnableWaitingMessage] = useState(false);
  const playerRef = useRef();
  const roomRef = doc(firebase.db, "rooms", locationData.id);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [enableVideo, setEnableVideo] = useState(true);
  const [isWaitingExceeded, setIsWaitingExceeded] = useState(false);
  const [appointmentStartedAt, setAppointmentStartedAt] = useState(null);
  const checkWaitingTimeExceeded = (startedAt) => {
    if (!enableWaitingMessage || !waitingTime) return;

    const waitDuration = moment().diff(moment(startedAt.toDate()), "minutes");
    setIsWaitingExceeded(waitDuration > waitingTime);
  };

  useEffect(() => {
    const getCurrentAppointment = () => {
      onSnapshot(roomRef, (roomData) => {
        const appointmentId = roomData.data().appointementId;
        if (!appointmentId) {
          resetState();
          return;
        }
        const appointmentRef = doc(firebase.db, "appointements", appointmentId);
        onSnapshot(appointmentRef, async (appointment) => {
          const appointmentData = appointment.data();
          if (!appointmentData) return;
          const {
            ended_at,
            technician,
            started_at,
            name,
            lastName,
            sex,
            birthday,
            enableVideo,
          } = appointmentData;
          setEnableVideo(enableVideo !== undefined ? enableVideo : true);
          setAppointmentStartedAt(started_at);
          if (ended_at) {
            resetState();
            return;
          }

          if (technician) {
            const userRef = doc(
              firebase.db,
              "users",
              appointmentData.technician_email
            );
            const technicianDoc = await getDoc(userRef);
            const technicianData = technicianDoc.data();
            if (technicianData?.profile_picture) {
              const imgUrl = await getDownloadURL(
                ref(getStorage(), technicianData.profile_picture)
              );
              setTechnicianImg(imgUrl);
            }
            setTechnician(technician.split(" ")[0]);
          }

          if (started_at && sex && birthday) {
            const age = moment().diff(moment(birthday, "DD/MM/YYYY"), "years");
            fetchVideos(sex, age);
            await fetchLaboratoryConfig(); // Fetch lab config to check waiting time
          }
          if (started_at) checkWaitingTimeExceeded(started_at);

          setFirstName(name || "");
          setLastName(lastName || "");
        });
      });
    };

    const fetchLaboratoryConfig = async () => {
      const labRef = doc(firebase.db, "laboratories", localStorage.currentLab);
      const labDoc = await getDoc(labRef);
      const labData = labDoc.data();
      if (labData) {
        setMessageLongWaiting(labData.messageLongWaiting || "");
        setWaitingTime(labData.waitingTime || 0);
        setEnableWaitingMessage(labData.enableWaitingMessage || false);
      }
    };

    const resetState = () => {
      setTechnician("");
      setTechnicianImg("");
      setFirstName("");
      setLastName("");
      setVideoUrls([]);
      setIsWaitingExceeded(false);
    };

    const fetchVideos = async (sex, age) => {
      const videoRef = collection(
        firebase.db,
        `laboratories/${localStorage.currentLab}/videos`
      );
      const q = query(videoRef, where("sex", "==", sex));
      const queryResult = await getDocs(q);
      const videoPaths = [];
      queryResult.forEach((queryVideo) => {
        const { path, minAge, maxAge } = queryVideo.data();
        if (minAge <= age && maxAge >= age) {
          videoPaths.push(path);
        }
      });
      const storage = getStorage();
      const urls = await Promise.all(
        videoPaths.map(async (path) => {
          const url = await getDownloadURL(ref(storage, path));
          return url;
        })
      );
      setVideoUrls(urls.reverse());
      setCurrentVideoIndex(0);
    };

    getCurrentAppointment();
  }, [firebase.db, locationData.id]);

  useEffect(() => {
    const checkWaitingInterval = setInterval(() => {
      if (!enableWaitingMessage || !waitingTime || !appointmentStartedAt)
        return;

      const waitDuration = moment().diff(
        moment(appointmentStartedAt.toDate()),
        "minutes"
      );
      setIsWaitingExceeded(waitDuration > waitingTime);
    }, 1000); // Vérifie toutes les minutes (60000 ms)

    return () => clearInterval(checkWaitingInterval); // Nettoie l'intervalle au démontage
  }, [enableWaitingMessage, waitingTime, appointmentStartedAt]);

  const handleVideoEnded = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoUrls.length);
  };

  const isVideoAvailable = videoUrls.length > 0 && enableVideo;

  return (
    <Grid container component="main" className={classes.root}>
      {localStorage.username && localStorage.role && (
        <Redirect push to="/home" />
      )}
      <CssBaseline />
      {isVideoAvailable ? (
        <VideoScreen
          firstName={firstName}
          lastName={lastName}
          technician={technician}
          technicianImg={technicianImg}
          videoUrl={videoUrls[currentVideoIndex]}
          handleVideoEnded={handleVideoEnded}
          playerRef={playerRef}
          classes={classes}
          isWaitingExceeded={isWaitingExceeded}
          messageLongWaiting={messageLongWaiting}
        />
      ) : (
        <DefaultScreen
          firstName={firstName}
          lastName={lastName}
          technician={technician}
          technicianImg={technicianImg}
          classes={classes}
          isWaitingExceeded={isWaitingExceeded}
          messageLongWaiting={messageLongWaiting}
        />
      )}
      <Animation />
    </Grid>
  );
}

function VideoScreen({
  firstName,
  lastName,
  technician,
  technicianImg,
  videoUrl,
  handleVideoEnded,
  playerRef,
  classes,
  isWaitingExceeded,
  messageLongWaiting,
}) {
  return (
    <>
      <div className={classes.customerInfoVideo}>
        <div>
          <Typography variant="h3" style={{ padding: "50px" }}>
            Bonjour,
          </Typography>
          <Typography variant="h4" className={classes.customer}>
            {firstName}
            <br />
            {lastName}
          </Typography>
        </div>

        {isWaitingExceeded && (
          <Typography
            variant="body1"
            style={{
              color: "#005284",
              marginTop: 10,
              marginLeft: 10,
              fontSize: "2.5rem",
            }}
          >
            {messageLongWaiting}
          </Typography>
        )}
      </div>

      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        playing
        onEnded={handleVideoEnded}
        width="70%"
        height="auto"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
        }}
        config={{
          file: {
            attributes: {
              controls: false,
              preload: "auto",
            },
          },
        }}
      />

      <div className={classes.technicianInfoVideo}>
        {technician ? (
          <>
            <Typography variant="h5" style={{ paddingBottom: 10 }}>
              Vous êtes pris en charge par :
            </Typography>
            <div style={{ position: "relative" }}>
              <img
                className={classes.imageVideo}
                src={technicianImg || "images/technician_placholder.jpg"}
                alt="Technician Profile"
              />
              <Typography variant="h5" className={classes.technicianVideo}>
                {technician}
              </Typography>
            </div>
          </>
        ) : (
          <DefaultTechnicianMessage classes={classes} isVideo />
        )}
      </div>
    </>
  );
}

function DefaultScreen({
  firstName,
  lastName,
  technician,
  technicianImg,
  classes,
  isWaitingExceeded,
  messageLongWaiting,
}) {
  return (
    <>
      <Grid item xs={6} className={classes.bgColor}>
        <div className={classes.section}>
          <Typography variant="h2" style={{ padding: "50px", width: "25vw" }}>
            Bonjour,
          </Typography>
          <Typography variant="h2" className={classes.customer}>
            {firstName}
            <br />
            {lastName}
          </Typography>
          {isWaitingExceeded && (
            <Typography
              variant="body1"
              style={{
                color: "#005284",
                marginTop: 10,
                marginLeft: 10,
                fontSize: "3rem",
              }}
            >
              {messageLongWaiting}
            </Typography>
          )}
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.section}>
          {technician ? (
            <>
              <Typography variant="h2" style={{ paddingBottom: 25 }}>
                Vous êtes pris en charge par :
              </Typography>
              <div style={{ position: "relative" }}>
                <img
                  className={classes.image}
                  src={technicianImg || "images/technician_placholder.jpg"}
                  alt="Technician Profile"
                />
                <Typography variant="h2" className={classes.technician}>
                  {technician}
                </Typography>
              </div>
            </>
          ) : (
            <DefaultTechnicianMessage classes={classes} />
          )}
        </div>
      </Grid>
    </>
  );
}

function DefaultTechnicianMessage({ classes, isVideo }) {
  const variant = isVideo ? "body1" : "h3";
  const padding = isVideo ? "50px 10px 10px" : "0 50px 50px";
  const iconClass = isVideo ? classes.iconVideo : classes.icon;
  const iconHolderClass = isVideo
    ? classes.iconHolderVideo
    : classes.iconHolder;

  return (
    <div style={{ position: "relative", width: isVideo ? 300 : undefined }}>
      <Typography variant={variant} style={{ padding: "50px" }}>
        Installez-vous sur le fauteuil, un préleveur va vous prendre en charge.
      </Typography>
      <Typography
        variant={variant}
        style={{ padding: isVideo ? "0 10px 5px" : "0 50px 25px" }}
      >
        Merci d’insérer votre carte vitale dans le lecteur et de préparer votre
        ordonnance et votre carte de mutuelle.
      </Typography>
      <div className={iconHolderClass}>
        <CreditCard className={iconClass} />
        <Description className={iconClass} />
      </div>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "hidden",
  },
  customer: {
    color: theme.palette.primary.main,
    textTransform: "capitalize",
  },
  section: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconHolder: {
    width: 500,
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: "-150px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  iconHolderVideo: {
    display: "flex",
    justifyContent: "space-around",
  },
  icon: {
    fontSize: "7rem",
    color: theme.palette.primary.main,
  },
  iconVideo: {
    fontSize: "3rem",
    color: theme.palette.primary.main,
  },
  image: {
    margin: 10,
    width: 500,
    height: 500,
    borderRadius: "50%",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
  technician: {
    textAlign: "center",
    position: "absolute",
    bottom: "-20px",
    left: "30%",
    width: "100%",
    backgroundColor: "rgba(41, 121, 255,0.8)",
    color: "#fff",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
  video: {
    width: "70%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
  },
  customerInfoVideo: {
    position: "absolute",
    top: "5%",
    left: "5%",
    zIndex: 10,
    backgroundColor: "#fff",
    padding: 10,
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.3)",
    maxWidth: 450,
  },
  technicianInfoVideo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    bottom: "5%",
    right: "3%",
    zIndex: 10,
    backgroundColor: "#fff",
    padding: 10,
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.3)",
  },
  imageVideo: {
    margin: 10,
    width: 150,
    height: 150,
    borderRadius: "50%",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
  technicianVideo: {
    textAlign: "center",
    position: "absolute",
    bottom: "-20px",
    left: "30%",
    width: "100%",
    backgroundColor: "rgba(41, 121, 255,0.8)",
    color: "#fff",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
}));
