import React from "react";
import { Button } from "@material-ui/core";
export default function CustomButton({
  title,
  children,
  variant,
  color,
  onClick,
  fullWidth = false,
  ...props
}) {
  return (
    <Button
      variant={variant ? variant : "contained"}
      color={color ? color : "primary"}
      onClick={onClick}
      fullWidth={fullWidth}
      {...props}
    >
      {title}
    </Button>
  );
}
