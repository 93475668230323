import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useFirebase } from "../../Firebase/FirebaseContext";
import moment from "moment";

export default function Timer({ roomId }) {
  const room = roomId;
  const { firebase } = useFirebase();
  const [refresh, setRefresh] = useState(false);
  const [timer, setTimer] = useState();
  const [currentAppointment, setCurrentAppointment] = useState();

  useEffect(() => {
    const roomRef = doc(firebase.db, `rooms/${room}`);
    getDoc(roomRef)
      .then((docData) => {
        if (docData.data().appointementId) {
          const currentAppointmentRef = doc(
            firebase.db,
            `appointements/${docData.data().appointementId}`
          );
          getDoc(currentAppointmentRef)
            .then((appDoc) => {
              console.log(appDoc.data().started_at);
              setCurrentAppointment(appDoc.data());
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));

    // Refresh current appointment every 5 seconds
    const interval = setInterval(() => {
      setRefresh(!refresh);
    }, 5000);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [firebase.db, refresh, room]);

  useEffect(() => {
    if (!currentAppointment) return;

    let interval = setInterval(() => {
      const a = moment();
      let b = moment.utc(currentAppointment.started_at?.seconds * 1000);
      let diff_s = a.diff(b, "seconds");
      let formatted = moment.utc(diff_s * 1000).format("HH:mm:ss");
      setTimer(formatted);
      console.log(formatted);
    }, 1000);

    // Clean up interval on unmount or when current appointment changes
    return () => clearInterval(interval);
  }, [currentAppointment]);

  return (
    <>
      <p style={{ fontSize: "16px", margin: 0 }}>
        le patient attend depuis {timer}
      </p>
      <p style={{ fontSize: "16px", margin: 0 }}>
        Prénom : {currentAppointment?.name}
      </p>
      <p style={{ fontSize: "16px", margin: 0 }}>
        Nom : {currentAppointment?.lastName}
      </p>
      <p style={{ fontSize: "16px", margin: 0 }}>
        {currentAppointment?.birthday}
      </p>
    </>
  );
}
