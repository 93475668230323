import React from "react";
import MUIDataTable from "mui-datatables";

export default function StickyHeadTable({
  AddButton = () => {
    return <div></div>;
  },
  title,
  columns,
  rows = [],
}) {
  const options = {
    filter: true, // Activer le filtrage global
    filterType: "multiselect", // Type de filtrage par colonne
    selectableRows: false,
  };

  return (
    <div>
      <h3>{title}</h3>
      <AddButton />
      <MUIDataTable data={rows} columns={columns} options={options} />
    </div>
  );
}
