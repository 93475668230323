import React from "react";

export default function Legend() {
  return (
    <div style={styles.container}>
      <div style={styles.legend}>
        <div style={styles.stickerAvailable}></div>
        <span style={styles.textAvailable}>Disponible</span>
      </div>
      <div style={styles.legend}>
        <div style={styles.stickerAppointed}></div>
        <span style={styles.textAppointed}>Salle occupée</span>
      </div>
      <div style={styles.legend}>
        <div style={styles.stickerTechnician}></div>
        <span style={styles.textTechnician}>
          Prise en charge par un préleveur
        </span>
      </div>
      {/* <div style={styles.legend}>
<div style={styles.stickerOccupy}></div>
<span style={styles.textOccupy}>Occupée</span>
</div> */}
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: 40,
  },
  stickerAvailable: {
    height: 20,
    width: 20,
    borderRadius: 30,
    backgroundColor: "#003171",
    overflow: "hidden",
    marginRight: 5,
  },
  textAvailable: {
    color: "#003171",
  },
  stickerAppointed: {
    height: 20,
    width: 20,
    borderRadius: 30,
    backgroundColor: "#454545",
    overflow: "hidden",
    marginRight: 5,
  },
  textAppointed: {
    color: "#454545",
  },
  stickerTechnician: {
    height: 20,
    width: 20,
    borderRadius: 30,
    backgroundColor: "#D35400",
    overflow: "hidden",
    marginRight: 5,
  },
  textTechnician: {
    color: "#D35400",
  },
  // stickerOccupy: {
  // height: 20,
  // width: 20,
  // borderRadius: 30,
  // backgroundColor: "#C9C9C9",
  // overflow: "hidden",
  // marginRight: 5,
  // },
  // textOccupy: {
  // color: "#C9C9C9",
  // },
  legend: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
  },
};
