import React from "react";
import { Form } from "react-final-form";

export default function FinalForm({
  onSubmit,
  initialValues,
  validate = () => {},
  style,
  children,
}) {
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={style}>
          {children}
        </form>
      )}
    />
  );
}
