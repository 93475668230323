import React from "react";
import { Typography } from "@material-ui/core";
export default function Title({ children }) {
  return (
    <Typography
      variant="h2"
      color="primary"
      align="center"
      style={{ marginTop: 50 }}
    >
      {children}
    </Typography>
  );
}
