import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const configuration = {
  apiKey: "AIzaSyAWbVg-uUpsldmoabcKLneBZZy9tzbcCxo",
  authDomain: "lybo-sylo-hygiene-com.firebaseapp.com",
  projectId: "lybo-sylo-hygiene-com",
  storageBucket: "lybo-sylo-hygiene-com.appspot.com",
  messagingSenderId: "37995367656",
  appId: "1:37995367656:web:2a14b7f67160729c97cd5d",
  measurementId: "G-BTBTL0FBY4",
};
const devConfig = {
  apiKey: "AIzaSyBgsvo5sm5TdSKUejxc1jggUXNrnm3VI2s",
  authDomain: "lybo-dev.firebaseapp.com",
  projectId: "lybo-dev",
  storageBucket: "lybo-dev.appspot.com",
  messagingSenderId: "930422481370",
  appId: "1:930422481370:web:485053e4d5485b58dae0e3",
};
class Firebase {
  constructor() {
    this.app = initializeApp(configuration);
    this.auth = getAuth();
    this.db = getFirestore();
    onAuthStateChanged(this.auth, (user) => {
      this.currentUser = user;
      // console.log(user);
    });
  }

  logout() {
    signOut(this.auth)
      .then(() => {
        console.log("Déconnexion du compte");
      })
      .catch((e) => {
        console.log("Erreur lors de la déconnexion");
      });
  }
}
export default Firebase;
