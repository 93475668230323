import React from "react";
import Header from "./Header";

export default function AppContainer({ children }) {
  return (
    <>
      <Header />
      <div
        style={{
          height: "100vh",
          width: "100%",
          position: "absolute",
          top: 0,
          zIndex: -1,
        }}
      ></div>
      {children}
    </>
  );
}
