import { makeStyles } from "@material-ui/core";

const Animation = () => {
  const classes = useStyles();
  return (
    <div
      style={{
        position: "absolute",
        overflow: "hidden",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <svg
        className={classes.waves}
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shapeRendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className={classes.parallax}>
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(41, 121, 255,0.6)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(41, 121, 255,0.4)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="6"
            fill="rgba(41, 121, 255,0.3)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="9"
            fill="rgba(41, 121, 255,0.2)"
          />
        </g>
      </svg>
    </div>
  );
};

export default Animation;
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  customer: {
    color: theme.palette.primary.main,
    textTransform: "capitalize",
  },
  section: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconHolder: {
    width: 500,
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: "-150px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  iconHolderVideo: {
    // width: 500,
    display: "flex",
    justifyContent: "space-around",
    // position: "absolute",
    // bottom: "-10px",
    // left: "50%",
    // transform: "translateX(-50%)",
  },
  icon: {
    fontSize: "7rem",
    color: theme.palette.primary.main,
  },
  iconVideo: {
    fontSize: "3rem",
    color: theme.palette.primary.main,
  },
  image: {
    margin: 10,
    width: 500,
    height: 500,
    borderRadius: "50%",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
    // border: `1px solid ${theme.palette.primary.main}`,
  },

  technician: {
    textAlign: "center",
    zIndex: 1,
    position: "absolute",
    bottom: "-20px",
    left: "30%",
    width: "100%",
    backgroundColor: "rgba(41, 121, 255,0.8)",
    color: "#fff",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
  waves: {
    position: "relative",
    width: "100%",
    height: "45vh",
    marginBottom: "-7px",
    minHeight: "25vh",
    maxHeight: "28vh",
    zIndex: -10,
  },
  parallax: {
    "& > use": {
      animation: "$move-forever 25s cubic-bezier(.550,.5,.45,.5) infinite",
    },
    "& > use:nth-child(1)": {
      animationDelay: "-3s",
      animationDuration: "21s",
    },
    "& > use:nth-child(2)": {
      animationDelay: "-4s",
      animationDuration: "30s",
    },
    "& > use:nth-child(3)": {
      animationDelay: "-5s",
      animationDuration: "39s",
    },
    "& > use:nth-child(4)": {
      animationDelay: "-6s",
      animationDuration: "60s",
    },
  },
  "@keyframes move-forever": {
    "0%": {
      transform: "translate3d(-90px,0,0)",
    },
    "100%": {
      transform: "translate3d(85px,0,0)",
    },
  },
  video: {
    width: "70%",
    // height: "70%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
    // backgroundColor: "#000",
  },
  customerInfoVideo: {
    position: "absolute",
    top: "5%",
    left: "5%",
    zIndex: 10,
    backgroundColor: "#fff",
    padding: 10,
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.3)",
  },
  technicianInfoVideo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    bottom: "5%",
    right: "3%",
    zIndex: 10,
    backgroundColor: "#fff",
    padding: 10,
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.3)",
  },
  imageVideo: {
    margin: 10,
    width: 150,
    height: 150,
    borderRadius: "50%",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
    // border: `1px solid ${theme.palette.primary.main}`,
  },

  technicianVideo: {
    textAlign: "center",
    zIndex: 1,
    position: "absolute",
    bottom: "-20px",
    left: "30%",
    width: "100%",
    backgroundColor: "rgba(41, 121, 255,0.8)",
    color: "#fff",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
}));
