import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  CssBaseline,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useFirebase } from "../../Firebase/FirebaseContext";
import CustomButton from "../Buttons/CustomButton";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    justifyContent: "center", // Center the content vertically
  },
  image: {
    backgroundImage: "url(/images/login.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing("auto", 8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  select: {
    minWidth: "200px",
  },
  tableContainer: {
    marginTop: theme.spacing(4),
  },
}));

export default function UserManagement() {
  const { firebase } = useFirebase();
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedFields, setEditedFields] = useState({});
  const [laboratories, setLaboratories] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState(null);

  const fetchUsers = async () => {
    const usersRef = collection(firebase.db, "users");
    const querySnapshot = await getDocs(usersRef);
    const usersData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setUsers(usersData);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleOpenEditDialog = (user) => {
    setEditUser(user);
    setEditedFields({
      name: user.name,
      email: user.id,
      status: user.status,
      lab: user?.belongs_to?.id,
    });
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleFieldChange = (field, value) => {
    setEditedFields((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSaveChanges = async () => {
    // Save the edited fields to the database
    try {
      const userRef = doc(firebase.db, "users", editUser.id);
      await updateDoc(userRef, {
        name: editedFields.name,
        email: editedFields.email,
        status: editedFields.status,
        belongs_to: editedFields.lab,
      });

      // Close the edit popup and fetch the updated users list
      handleCloseEditDialog();
      fetchUsers();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleOpenDeleteConfirmation = (user) => {
    setSelectedUserToDelete(user);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setSelectedUserToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleConfirmDeleteUser = async () => {
    // Delete the user from the database
    try {
      const userRef = doc(firebase.db, "users", selectedUserToDelete.id);
      await deleteDoc(userRef);

      // Fetch the updated users list
      fetchUsers();
      handleCloseDeleteConfirmation();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const laboratoriesRef = collection(firebase.db, "laboratories");

  useEffect(() => {
    getDocs(laboratoriesRef).then((querySnapshot) => {
      const labs = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        path: doc.ref.path,
        value: doc.id,
      }));
      setLaboratories(labs);
    });
  }, []);

  return (
    <>
      <Dialog
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        aria-labelledby="edit-dialog-title"
      >
        <DialogTitle id="edit-dialog-title">Modifier l'utilisateur</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Nom"
              value={editedFields.name}
              onChange={(e) => handleFieldChange("name", e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              value={editedFields.email}
              onChange={(e) => handleFieldChange("email", e.target.value)}
              fullWidth
              margin="normal"
            />
            <InputLabel id="status-label">Statut</InputLabel>
            <Select
              labelId="status-label"
              value={editedFields.status}
              onChange={(e) => handleFieldChange("status", e.target.value)}
            >
              <MenuItem value="hostess">Hotesse</MenuItem>
              <MenuItem value="technician">Préleveur</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="lab-label">Laboratoire</InputLabel>
            <Select
              labelId="lab-label"
              value={editedFields.lab}
              onChange={(e) => handleFieldChange("lab", e.target.value)}
            >
              {laboratories.map((lab) => (
                <MenuItem key={lab.path} value={lab.value}>
                  {lab.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSaveChanges} color="primary">
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirmation de suppression
        </DialogTitle>
        <DialogContent>
          <Typography>
            Êtes-vous sûr de vouloir supprimer cet utilisateur ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDeleteUser} color="primary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Gestion des utilisateurs
          </Typography>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Laboratoire</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{`${user.name}`}</TableCell>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.status}</TableCell>
                    <TableCell>{user?.belongs_to?.id}</TableCell>
                    <TableCell>
                      <CustomButton
                        onClick={() => handleOpenEditDialog(user)}
                        title="Modifier"
                      />
                      <CustomButton
                        onClick={() => handleOpenDeleteConfirmation(user)}
                        title="Supprimer"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="https://gyra-lean.com/">
                GYRA
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </div>
      </Grid>
    </>
  );
}
