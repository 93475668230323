import {
  makeStyles,
  Typography,
} from "@material-ui/core";
import { collection, getDocs } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../../../Firebase/FirebaseContext";
import CustomButton from "../../Buttons/CustomButton";
import Title from "../../Elements/Title";
import ReactWebMediaPlayer from "react-web-media-player";

export default function AllVideo() {
  const classes = useStyles();
  const history = useHistory();
  const { firebase } = useFirebase();
  const [videos, setVideos] = useState();
  const [refresh, setRefresh] = useState(false);
  const [tableOfLink, settableOfLink] = useState([]);
  const storage = getStorage();

  const recursifOfLink = async (  
    objectResults,
    arrayParcourir,
    currentIndex
  ) => {
    if (currentIndex > arrayParcourir.length - 1) {
      settableOfLink(objectResults);
      return;
    }
    const path = await getDownloadURL(ref(storage, arrayParcourir[currentIndex].link));
    let temp = { ...objectResults, [arrayParcourir[currentIndex].id]: path };
    await recursifOfLink(temp, arrayParcourir, currentIndex + 1);
  };

  useEffect(() => {
    (async () => {
      try {
        const videosRef = collection(firebase.db, `videos`);
        const videosDocs = await getDocs(videosRef);
        let temp = [];
        videosDocs.forEach((video) =>
          temp.push({
            id: video.id,
            url: video.data().path,
            name: {
              key: "Nom",
              data: video.data().title,
            },
            createdAt: {
              key: "Date d'ajout",
              data: moment
                .unix(video.data().createdAt.seconds)
                .format("DD/MM/YYYY HH:mm:ss"),
            },
            link: video.data().path,
          })
        );
        await recursifOfLink({}, temp, 0);
        let orderedArr = temp.sort((a, b) =>
          a.name.data.localeCompare(b.name.data)
        );
        setVideos(orderedArr);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [refresh]);

  const redirectToEdit = (id) => {
    history.push("/AttribVideo", {
      id: id,
    });
  };
  return (
    <>
      <Title>Les vidéos</Title>
      <div className={classes.flexContainer}>
        {videos &&
          videos.map((video) => {
            console.log(video.link);
            return (
              <div className={classes.videoItem}>
                {Object.keys(video).map(
                  (item, i) =>
                    video[item].data &&
                    video[item] && (
                      <Typography key={`appointment-item-${i}`}>
                        {video[item].key} : {video[item].data}
                      </Typography>
                    )
                )}
                {tableOfLink[video.id] && (
                  <ReactWebMediaPlayer
                    title={video.url}
                    video={tableOfLink[video.id]}
                    thumbnail=""
                  />
                )}
                <CustomButton
                  style={{ marginTop: 10, backgroundColor: "rgb(88 223 86)" }}
                  title="Attribuer la vidéo"
                  fullWidth
                  onClick={() => redirectToEdit(video.id)}
                />
              </div>
            );
          })}
      </div>
    </>
  );
}
const useStyles = makeStyles(() => ({
  flexContainer: {
    display: "flex",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "2rem",
    margin: "2rem",
    flexWrap:"wrap",
    justifyContent: "space-around",
  },
  videoItem: {
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.4)",
    padding: 20,
  },
}));
