import { Box, Paper, Typography, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useFirebase } from "../../Firebase/FirebaseContext";
import moment from "moment";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Title from "../Elements/Title";
import StickyHeadTable from "../Elements/Datatable";

const columns = [
  {
    name: "date",
    label: "Date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        if (value.toLocaleDateString) {
          return value.toLocaleDateString(); // Formatage de la date
        }
        return value;
      },
    },
  },
  {
    name: "salle",
    label: "Salle",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "lastName",
    label: "Nom",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Prénom",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "age",
    label: "Age",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "sex",
    label: "Sexe",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "technician",
    label: "Préleveur",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "choice",
    label: "Type de rdv",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "duration",
    label: "Temps d'intervention (hh:mm:ss)",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "created_at",
    label: "Création de rdv",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "started_at",
    label: "Début de rdv",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "technician_came_at",
    label: "Arrivée technicien",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "ended_at",
    label: "Fin de rdv",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "waiting_duration",
    label: "Durée d’attente avant prélèvement",
    options: {
      filter: false,
      sort: true,
    },
  },
];
ChartJS.register(ArcElement, Tooltip, Legend);

export default function Statistics() {
  const { firebase } = useFirebase();
  const [average, setAverage] = useState();
  const [numberOfSexes, setNumberOfSexes] = useState();
  const [patientsAge, setPatientsAge] = useState();
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState();
  const [filteredData, setFilteredData] = useState();

  const getDurationOfSampling = (startTime, endTime) => {
    const a = moment.unix(startTime);
    let b = moment.unix(endTime);
    let diff_s = b.diff(a, "seconds");
    let formatted = moment.utc(diff_s * 1000).format("HH:mm:ss");
    return formatted;
  };

  const getGlobalAverageDurationOfSamplings = (array) => {
    let times = [3600, 60, 1];
    let parts = array.map((s) =>
      s.split(":").reduce((s, v, i) => s + times[i] * v, 0)
    );
    let avg = Math.round(parts.reduce((a, b) => a + b, 0) / parts.length);

    return times
      .map((t) => [Math.floor(avg / t), (avg %= t)][0])
      .map((v) => v.toString().padStart(2, 0))
      .join(":");
  };

  const sexCount = (data) => {
    // Homme, Femme, Non Communiqué
    let sexes = [0, 0, 0];
    data.forEach((appointnment) => {
      if (appointnment.sex === "Homme") sexes[0]++;
      else if (appointnment.sex === "Femme") sexes[1]++;
      else sexes[2]++;
    });
    return sexes;
  };

  const ageCount = (data) => {
    const ages = Array(11).fill(0);
    const ageRanges = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

    data.forEach((appointment) => {
      const { age } = appointment;
      for (let i = 0; i < ageRanges.length; i++) {
        if (age <= ageRanges[i]) {
          ages[i]++;
          break;
        }
      }
    });

    return ages;
  };
  useEffect(() => {
    (async () => {
      const roomsRef = collection(firebase.db, `rooms`);
      let roomsData = await getDocs(roomsRef);
      let filteredRooms = [];
      roomsData.docs.forEach((item) => {
        if (item.data().laboratoryId == localStorage.getItem("currentLab")) {
          filteredRooms.push({ id: item.id, ...item.data() });
        }
      });
      const appointmentsRef = collection(firebase.db, `appointements`);
      const q = query(
        appointmentsRef,
        orderBy("ended_at", "desc"),
        where("laboratoryId", "==", localStorage.getItem("currentLab"))
      );

      try {
        let times = [];
        const qSnapshot = await getDocs(q);

        let temp = [];
        qSnapshot.forEach((appointmentDoc) => {
          let docInfos = appointmentDoc.data();

          const duration = getDurationOfSampling(
            docInfos.started_at.seconds,
            docInfos.ended_at.seconds
          );
          console.log(docInfos);
          const created_date = docInfos.created_at?.seconds
            ? moment.unix(docInfos.created_at.seconds).format("DD/MM/yyyy")
            : new Date(docInfos.created_at);
          const data = {
            choice: docInfos.choice,
            date: created_date,
            salle: filteredRooms.find((item) => item.id == docInfos.roomId)
              ?.name,
            lastName: docInfos.lastName,
            technician_came_at: moment
              .unix(docInfos.technician_came_at.seconds)
              .format("HH:mm:ss"),
            started_at: moment
              .unix(docInfos.started_at.seconds)
              .format("HH:mm:ss"),
            ended_at: moment.unix(docInfos.ended_at.seconds).format("HH:mm:ss"),
            created_at: moment
              .unix(docInfos.created_at.seconds)
              .format("HH:mm:ss"),
            name: docInfos.name,
            age: docInfos.birthday
              ? moment().diff(moment(docInfos.birthday, "DD/MM/YYYY"), "years")
              : null,
            sex:
              (docInfos.sex === "h" && "Homme") ||
              (docInfos.sex === "f" && "Femme"),
            technician: docInfos.technician.replace("undefined", "").trim(),
            duration: duration,
            samplingDuration: duration,
            waiting_duration: moment
              .utc(
                moment
                  .unix(docInfos.technician_came_at.seconds)
                  .diff(moment.unix(docInfos.started_at.seconds))
              )
              .format("HH:mm:ss"),
          };

          times.push(data.samplingDuration);
          temp.push(data);
        });
        setNumberOfSexes(sexCount(temp));
        setPatientsAge(ageCount(temp));
        setAverage(getGlobalAverageDurationOfSamplings(times));
        setAppointments(temp);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const sexPieChart = {
    labels: ["Homme", "Femme", "Non communiqué"],
    datasets: [
      {
        label: "# of Patients by gender",
        data: numberOfSexes,
        backgroundColor: [
          "rgba(51, 191, 255, 0.4)",
          "rgba(221, 51, 250, 0.4)",
          "rgba(150, 150, 150, 0.4)",
        ],
        borderColor: [
          "rgba(51, 191, 255, 1)",
          "rgba(221, 51, 250, 1)",
          "rgba(150, 150, 150, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const agePieChart = {
    labels: [
      "0-10",
      "11-20",
      "21-30",
      "31-40",
      "41-50",
      "51-60",
      "61-70",
      "71-80",
      "81-90",
      "91-100",
      "Non communiqué",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: patientsAge,
        backgroundColor: [
          "rgba(247, 51, 120, 0.4)",
          "rgba(221, 51, 250, 0.4)",
          "rgba(131, 75, 255, 0.4)",
          "rgba(51, 191, 255, 0.4)",
          "rgba(51, 234, 255, 0.4)",
          "rgba(74, 237, 196, 0.4)",
          "rgba(145, 255, 53, 0.4)",
          "rgba(255, 238, 51, 0.4)",
          "rgba(255, 167, 51, 0.4)",
          "rgba(209, 255, 51, 0.4)",
          "rgba(150, 150, 150, 0.4)",
        ],
        borderColor: [
          "rgba(247, 51, 120, 1)",
          "rgba(221, 51, 250, 1)",
          "rgba(131, 75, 255, 1)",
          "rgba(51, 191, 255, 1)",
          "rgba(51, 234, 255, 1)",
          "rgba(74, 237, 196, 1)",
          "rgba(145, 255, 53, 1)",
          "rgba(255, 238, 51, 1)",
          "rgba(255, 167, 51, 1)",
          "rgba(209, 255, 51, 1)",
          "rgba(150, 150, 150, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Title>Statistiques</Title>
      {!loading ? (
        <>
          <Typography
            variant="h5"
            color="primary"
            align="center"
            style={{ marginTop: 20 }}
          >
            Nombre de rendez-vous :{" "}
            {(filteredData && filteredData.length) ||
              (appointments && appointments.length)}
          </Typography>
          {average && (
            <Typography color="primary" variant="h5" align="center">
              Temps d'intervention moyen : {average}
            </Typography>
          )}
          <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <Paper
              style={{
                margin: 25,
                padding: 25,
                width: 350,
              }}
            >
              <Typography
                style={{ textAlign: "center" }}
                variant="h6"
                color="primary"
              >
                Sexes des patients
              </Typography>
              <Pie data={sexPieChart} />
            </Paper>
            <Paper
              style={{
                margin: 25,
                padding: 25,
                width: 350,
              }}
            >
              <Typography
                style={{ textAlign: "center" }}
                variant="h6"
                color="primary"
              >
                Ages des patients
              </Typography>
              <Pie data={agePieChart} />
            </Paper>
          </Box>

          <div style={{ padding: 25 }}>
            <StickyHeadTable
              columns={columns}
              rows={appointments}
            ></StickyHeadTable>
          </div>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}
